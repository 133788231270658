var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
      !_vm.hideOrganization
    )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.serviceCenter.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.serviceCenter.id},on:{"organizationChanged":(organizationId) => {
          _vm.serviceCenter.organization.id = organizationId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"required":true},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.serviceCenter.name),callback:function ($$v) {_vm.$set(_vm.serviceCenter, "name", $$v)},expression:"serviceCenter.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1)]),_c('base-checkbox',{staticClass:"mb-3",on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.serviceCenter.has_warehouse),callback:function ($$v) {_vm.$set(_vm.serviceCenter, "has_warehouse", $$v)},expression:"serviceCenter.has_warehouse"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.HAS_WAREHOUSE"))+" ")])]),_c('base-checkbox',{staticClass:"mb-3",on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.serviceCenter.has_folder),callback:function ($$v) {_vm.$set(_vm.serviceCenter, "has_folder", $$v)},expression:"serviceCenter.has_folder"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.HAS_FOLDER"))+" ")])]),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.serviceCenter.id ? _vm.$t("SERVICECENTERS.EDIT_SERVICECENTER") : _vm.$t("SERVICECENTERS.ADD_SERVICECENTER"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  type: "service-centers",
  name: null,
  has_warehouse: false,
  has_folder: true,
  relationshipNames: ["organization", "establishments"],
  organization: {
    type: "organizations",
    id: null,
  },
  allowedUsers: [],
};
